<template lang="html">

  <section class="features">
    <div class="container d-flex aic jcc -reverse head">
      <Waypoint class="content" effect="fadeIn">
        <h1 class="title-big -blueDarkC">{{$t('features.intro1')}}</h1>
        <p class="description ">
         {{$t('features.texto1')}}
        </p>
        <p class="description ">
          {{$t('features.texto2')}}
        </p>

      </Waypoint>
      <Waypoint class="div-img d-flex jcc aic" effect="fadeInRight">
        <img src="/img/bg/tel.png" alt="mobile head features" class="mobile">
        
       
      </Waypoint>
    </div>
    <section class="driver-management">
      <div class="content-driver">
        <!-- <img src="/img/icons/detail-double.svg" class="img-detail" alt="detail"> -->
        <Waypoint id="lineDouble">
          <div class="img-detail">
            <Vue3Lottie v-if="animationState.lineDouble" :animationData="LineDouble" :height="auto" width="100%" direction="forward" :loop='1' />
          </div>
        </Waypoint>
        <div class="container column-center">
          <img src="/img/icons/driver-management-icon.svg" alt="Driver Management" class="title-driver">
          <h2 class="title-big  -blueDarkC">
           {{$t('features.driver_management')}}
          </h2>
          <div class="container-features">
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/driver-application.svg" alt="{{$t('features.cuadro1')}}">
              <p>{{$t('features.cuadro1')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/holiday-sickness-icon.svg" alt="{{$t('features.cuadro2')}}">
              <p>{{$t('features.cuadro2')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/accident-incident.svg" alt="{{$t('features.cuadro3')}}">
              <p>{{$t('features.cuadro3')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/uniform.svg" alt="{{$t('features.cuadro4')}}">
              <p>{{$t('features.cuadro4')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/asset-assignement.svg"
                alt="{{$t('features.cuadro5')}}">
              <p>{{$t('features.cuadro5')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/geolocalisation.svg" alt="{{$t('features.cuadro6')}}">
              <p>{{$t('features.cuadro6')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/employee-fault.svg" alt="{{$t('features.cuadro7')}}">
              <p>{{$t('features.cuadro7')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/hours-days.svg" alt="{{$t('features.cuadro8')}}">
              <p>{{$t('features.cuadro8')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/expensed.svg" alt="{{$t('features.cuadro9')}}">
              <p>{{$t('features.cuadro9')}}</p>
            </Waypoint>
          </div>
        </div>
      </div>
      <reserva></reserva>
    </section>
    
    <div class="incetive-management">
      
      <img src="/img/icons/detail1_blue.svg" alt="detail" class="img-detail-circle">
      <div class="container d-flex aic jcc -reverse head ">
        <Waypoint effect="fadeIn" class="content">
          <h2 class="title-medium -blueDarkC">{{$t('features.intro3')}}</h2>
          <p class="description ">
           {{$t('features.texto3')}}
          </p>
          <ul>
            <li>
              <p>{{$t('features.texto3_2')}}</p>
            </li>
            <li>
              <p>{{$t('features.texto3_3')}}</p>
            </li>
          </ul>
        </Waypoint>
        <div class="div-img d-flex jcc aic">
          <div class="img-detail-bg">
            <img src="/img/bg/img-incentive.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <section class="driver-management fleet-management">
      <div class="content-driver">
        <Waypoint id="lineDouble">
          <div class="img-detail">
            <Vue3Lottie v-if="animationState.lineDouble" :animationData="LineDouble" :height="auto" width="100%"
              direction="forward"  />
          </div>
        </Waypoint>
        <!-- <img src="/img/icons/detail-double.svg" class="img-detail" alt="detail"> -->
        <div class="container column-center">
          <img src="/img/icons/fleet-management-circle.svg" alt="Fleet Management" class="title-driver">
          <h2 class="title-big   -blueDarkC">
           {{$t('features.fleet_management')}}
          </h2>
          <div class="container-features">
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/full-long.svg" alt="{{$t('features.cuadro1_2')}}">
              <p>{{$t('features.cuadro1_2')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/assignment-vehicles.svg" alt="{{$t('features.cuadro2_2')}}">
              <p>{{$t('features.cuadro2_2')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/contract.svg" alt="{{$t('features.cuadro3_2')}}">
              <p>{{$t('features.cuadro3_2')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/petrol.svg"
                alt="{{$t('features.cuadro4_2')}}">
              <p>{{$t('features.cuadro4_2')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/accident-reporting.svg"
                alt="{{$t('features.cuadro5_2')}}">
              <p>{{$t('features.cuadro5_2')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/vehicle-inspection.svg"
                alt="{{$t('features.cuadro6_2')}}">
              <p>{{$t('features.cuadro6_2')}}
              </p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/legat-permit.svg" alt="{{$t('features.cuadro7_2')}}">
              <p>{{$t('features.cuadro7_2')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/mileage-tracking.svg" alt="{{$t('features.cuadro8_2')}}">
              <p>{{$t('features.cuadro8_2')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/maintenance.svg" alt="{{$t('features.cuadro9_2')}}">
              <p>{{$t('features.cuadro9_2')}}</p>
            </Waypoint>
            <Waypoint effect="fadeIn" class="box">
              <img src="/img/icons/fine-fault.svg" alt="{{$t('features.cuadro10_2')}}">
              <p>{{$t('features.cuadro10_2')}}</p>
            </Waypoint>
          </div>
        </div>
      </div>

    </section>
    <div class="incetive-management asset-management">
      <img src="/img/icons/detail1_blue.svg" alt="detail" class="img-detail-circle">
      <div class="container d-flex aic jcc -reverse head ">
        <Waypoint effect="fadeIn" class="content">

          <h3 class="title-medium -blueDarkC">{{$t('features.asset_management')}}</h3>
          <ul>
            <li>
              <p>{{$t('features.asset_management_1')}}</p>
            </li>
            <li>
              <p>{{$t('features.asset_management_2')}}</p>
            </li>
            <li>
              <p>{{$t('features.asset_management_3')}}</p>
            </li>
            <li>
              <p>{{$t('features.asset_management_4')}}</p>
            </li>
          </ul>
          <h3 class="title-medium -blueDarkC">{{$t('features.full_reporting')}}</h3>
          <ul>
            <li>
              <p>{{$t('features.full_reporting_1')}}</p>
            </li>
            <li>
              <p>{{$t('features.full_reporting_2')}}</p>
            </li>
            <li>
              <p>{{$t('features.full_reporting_3')}}</p>
            </li>
          </ul>
        </Waypoint>
        <div class="div-img d-flex jcc aic">
          <div class="img-detail-bg segunda">
            <img src="/img/bg/img-asset-management.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <newsletter></newsletter>
  </section>

</template>

<script lang="js">
  import { Waypoint } from 'vue-waypoint'
  import { Vue3Lottie } from 'vue3-lottie'
    import 'vue3-lottie/dist/style.css'
  import LineDouble from '@/lotties/detail_double_blue.json'
  import newsletter from '@/components/newsletter'
  import reserva from '@/components/reserva'
  export default {
    name: 'features',
    props: [],
    components: {
      Waypoint,
      Vue3Lottie,
      'newsletter':newsletter,
      'reserva':reserva
    },
    mounted() {

    },
    data() {
      return {
        animationState: {
          lineDouble: false,
        },
        LineDouble
      }
    },
    methods: {
      onChange(waypointState) {
        if (this.animationState[waypointState.el.id] === false) {
          this.animationState[waypointState.el.id] = waypointState.going == 'IN'
        }
      }
    },
    computed: {

    }
  }


</script>
<style>
  @media screen and (max-width:900px){
    .about-us .slider-full .slide .container-slide .div-text {

    padding-left: 0px;
}
    .title-big{
      font-size: 38px;
    }

          
    
               .div-img img {
                    max-width: 100% !important;
    
                }
    
  }
</style>